import React from 'react';
import AsyncSelect from 'react-select/async';
import * as edit from 'react-edit';
import * as search from 'searchtabular';
import {
  editingCell,
  sortingHeader,
  ActionLink,
  AddNameAttribute,
  Button,
  Checkbox
} from './helpers/table_def_funcs';
import TeachingPointModal from 'NewEditDoc/TeachingPointModal/component'
import DragDrop from 'app/assets/images/drag_and_drop.svg'

const ROSTERING_TIMING_OPTIONS = [
  {name: 'Standard', value: 1.0},
  {name: '1.5x'    , value: 1.5},
  {name: '2.0x'    , value: 2.0}
];

const CASE_REPORT_OPTIONS = [
  {name: 'Select Report', value: 'select_report'},
  {name: 'Project Status Report', value: 'project_status_report'},
  {name: 'Project Workflow Report', value: 'project_workflow_report'},
  {name: 'Summary of Edits Report', value: 'summary_of_edits_report'},
  {name: 'Cases Content Report', value: 'cases_content_report'},
  {name: 'Project Editors Report', value: 'project_editors_report'},
  {name: 'Comments Report', value: 'comments_report'}
];

const ASSESSMENT_REPORT_OPTIONS = [
  {name: 'Select Report', value: 'select_report'},
  {name: 'Project Status Report', value: 'project_status_report'},
  {name: 'Project Workflow Report', value: 'project_workflow_report'},
  {name: 'Summary of Edits Report', value: 'summary_of_edits_report'},
  {name: 'Project Editors Report', value: 'project_editors_report'}
];

const CCI_PROJECT_ROLE_OPTIONS = [
  {name: 'Reviewer', value: 'reviewer'},
  {name: 'Case Editor', value: 'case_editor'},
  {name: 'Production Associate', value: 'production_associate'},
  {name: 'Associate Editor', value: 'associate_editor'},
  {name: 'Assessment Lead', value: 'assessment_lead'},
  {name: 'Editor-in-Chief', value: 'editor_in_chief'}
];

const FLAGGED_ASSESSMENT_PROJECT_ROLE_OPTIONS = [
  {name: 'Primary Editor', value: 'primary_editor'},
  {name: 'Production Associate', value: 'production_associate'},
  {name: 'Dyad Reviewer', value: 'dyad_reviewer'},
  {name: 'Group Reviewer', value: 'group_reviewer'},
  {name: 'Assessment Lead', value: 'assessment_lead'},
  {name: 'Editor-in-Chief', value: 'editor_in_chief'},
  {name: 'Copy Editor', value: 'copy_editor'}
];

const NEW_ASSESSMENT_PROJECT_ROLE_OPTIONS = [
  {name: 'Author', value: 'author'},
  {name: 'Production Associate', value: 'production_associate'},
  {name: 'Dyad Reviewer', value: 'dyad_reviewer'},
  {name: 'Group Reviewer', value: 'group_reviewer'},
  {name: 'Assessment Lead', value: 'assessment_lead'},
  {name: 'Editor-in-Chief', value: 'editor_in_chief'},
  {name: 'Copy Editor', value: 'copy_editor'}
];

export const TABLE_DEFS = {
  project_editors_report_table: function() {
    const editable = editingCell.call(this);
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'first_name',
        header: {
          label: 'First Name',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'last_name',
        header: {
          label: 'Last Name',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'email',
        header: {
          label: 'Email',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'project_role',
        header: {
          label: 'Project Role',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'course',
        header: {
          label: 'Course',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'project_name',
        header: {
          label: 'Project Status Report',
        },
        cell: {
          formatters: [
            (project_name, extraParameters) => {
              if (extraParameters.rowData.project_status_report_url === "n/a") {
                return "n/a"
              } else {
                return <a href={extraParameters.rowData.project_status_report_url} target='_blank'>{project_name}</a>
              }
            }
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        props: {
          style: {
            textAlign: 'center',
          },
          className: "button-column"
        },
        visible: true
      },
    ];},

  new_item_authors_report_table: function(){
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'author_name',
        header: {
          label: 'Author\'s Name',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'course',
        header: {
          label: 'Course',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'case',
        header: {
          label: 'Case #',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {

            }
          }
        },
        visible: true
      },
      {
        property: 'project_name',
        header: {
          label: 'Project Name',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'item_label',
        header: {
          label: 'Item Label',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'author_start_date',
        header: {
          label: 'Author\'s Start Date',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'author_completion_date',
        header: {
          label: 'Author\'s Completion Date',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'days_author_start_to_complete',
        header: {
          label: '# Days Author Start to Complete',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
    ];},

  student_performance_report: function() {
    const editable = editingCell.call(this);
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'teaching_point_title',
        header: {
          label: 'Teaching Point',
          formatters: [sortableHeader],
        },
        cell: {
          formatters: [
            (teaching_point_title, extraParameters) => {
              return (
                <TeachingPointModal
                  title={teaching_point_title}
                  document_id={extraParameters.rowData.document_id}
                  publish_version_id={extraParameters.rowData.publish_version_id}
                  teaching_point_uid={extraParameters.rowData.teaching_point_uid}
                />
              )
            }
          ]
        },
        visible: true
      },
      {
        property: 'system',
        header: {
          label: 'System',
          formatters: [sortableHeader]
        },
        cell: {
          formatters: [search.highlightCell],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'learning_objective',
        header: {
          label: 'Learning Objective',
          formatters: [sortableHeader]
        },
        cell: {
          formatters: [search.highlightCell]
        },
        visible: true
      },
      {
        property: 'accuracy',
        header: {
          label: 'Accuracy',
          formatters: [sortableHeader]
        },
        cell: {
          formatters: [search.highlightCell],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'certainty',
        header: {
          label: 'Certainty',
          formatters: [sortableHeader]
        },
        cell: {
          formatters: [search.highlightCell],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'clinical_learning_calibration',
        header: {
          label: 'Clinical Learning Calibration',
          formatters: [sortableHeader]
        },
        cell: {
          formatters: [search.highlightCell],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'case_completed',
        header: {
          label: 'Case Completed',
          formatters: [sortableHeader]
        },
        cell: {
          formatters: [search.highlightCell],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'case_name',
        header: {
          label: 'Case',
          formatters: [sortableHeader]
        },
        cell: {
          formatters: [
              (case_name, extraParameters) => <a href={extraParameters.rowData.case_path} target='_blank'>{case_name}</a>
            ]
        },
        visible: true
      }
    ];},

  case_content_report_table: function() {
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'course_name',
        header: {
          label: 'Course',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 300
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'case_number',
        header: {
          label: 'Case #',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 100
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'case_title',
        header: {
          label: 'Case Title',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 200
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {

            }
          }
        },
        visible: true
      },
      {
        property: 'word_count_published_version',
        header: {
          label: 'Word count (published)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'word_count_SR_version',
        header: {
          label: 'Word count (SR)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_pages_published_version',
        header: {
          label: '# Pages (published)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_pages_SR_version',
        header: {
          label: '# Pages (SR)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_teaching_points_published_version',
        header: {
          label: '# Teaching Points (published)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 200
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_teaching_points_SR_version',
        header: {
          label: '# Teaching Points (SR)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 200
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_deep_dives_published_version',
        header: {
          label: '# Deep Dives (published)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_deep_dives_SR_version',
        header: {
          label: '# Deep Dives (SR)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_engagement_questions_published_version',
        header: {
          label: '# Engagement Questions (published)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_engagement_questions_SR_version',
        header: {
          label: '# Engagement Questions (SR)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_SAQs_published_version',
        header: {
          label: '# SAQs (published)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'number_of_SAQs_SR_version',
        header: {
          label: '# SAQs (SR)',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
              width: 150
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
    ];},

  project_team_table: function() {
    const editable = editingCell.call(this);
    const deleteAction = this.props.delete_action
    const showEditColumns = this.props.show_edit_columns
    const loadOptions = (inputValue, callback) => {
      let url = new URL(this.props.editors_url)
      url.searchParams.set('q', inputValue)

      fetch(url)
        .then(response => response.json())
        .then(editorData => {
          editorData = [{label: '<none>', value: null}, ...editorData]
          callback(editorData)
        })
    };

    this.handleInputChange = (newValue) => {
      const inputValue = newValue.replace(/\W/g, '');
      this.setState({ inputValue });
      return inputValue;
    };

    this.handleEditors = (newValue, url) => {
      $.ajax({
        url: url,
        type: 'PATCH',
        data: { user_id: newValue.value },
        async: false,
        dataType: 'json',
        success: function (response) {
        },
        error: function(message) {
          alert(message.responseText);
        }
      });
    }

    let projectRoleOptions = []
    if(this.props.project_type == "Projects::CaseProject"){
      projectRoleOptions = CCI_PROJECT_ROLE_OPTIONS
    }else if(this.props.project_type == "Projects::FlaggedAssessmentProject"){
      projectRoleOptions = FLAGGED_ASSESSMENT_PROJECT_ROLE_OPTIONS
    }else{
      projectRoleOptions = NEW_ASSESSMENT_PROJECT_ROLE_OPTIONS
    }

    return [
      {
        header: {},
        cell: {
          formatters: [
            (_, extra) => {
              return <img src={DragDrop} alt="Drag and Drop Button"/>
            }
          ]
        },
        props: {
          style: {
            width: 50,
            backgroundColor: '#EFEFEF'
          },
          className: "button-column drag-me-only"
        },
        visible: true
      },
      {
        property: 'status',
        header: {
          label: 'Status',
          formatters: []
        },
        cell: {
          transforms: [],
          formatters: [
            (name, extraParameters) => <span style= {{backgroundColor: extraParameters.rowData.status == 'completed' ? '#FDFD96' : extraParameters.rowData.status == 'active' ? '#C1E1C1' : null  }}> {extraParameters.rowData.status }</span>
          ],
          props: {}
        },
        props: {
          style: {
            width: 130
          },
        },
        visible: true
      },
      {
        property: 'role',
        header: {
          label: 'Project Role',
          formatters: [],
          props: {}
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: projectRoleOptions,
              }))],
          formatters: [
            (role, extraParameters) => AddNameAttribute(
              'role',
              extraParameters,
              this.props.name_prefix,
              <span className="dropdown-cell">{projectRoleOptions.find(elem => elem.value == extraParameters.rowData.role).name}</span>
            )
          ]
        },
        props: {
          style: {
            width: 280
          },
        },
        visible: true
      },
      {
        property: 'user_label',
        header: {
          label: 'User',
          formatters: [],
          props: {
            style: {
              width: '60%'
            },
          }
        },
        cell: {
          transforms: [],
          formatters: [
            (val, extra) => {
              return <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                name={this.props.team_member_user_id_field_name}
                defaultValue={{label: extra.rowData['user_label'], value: extra.rowData['user_id']}}
                // onInputChange={this.handleInputChange}
                // onChange={(value) => this.handleEditors(value, extra.rowData.update_member_path)}
              />
            }
          ]
        },
        visible: true
      },
      {
        property: 'member_id',
        header: {
          label: '',
          formatters: [],
          props: {style: {
            display: 'none'
            },
          }
        },
        cell: {
          transforms: [],
          formatters: [
            (val, extra) => {
              return <AsyncSelect
                name={this.props.member_id_field_name}
                defaultValue={{label: extra.rowData['member_id'], value: extra.rowData['member_id']}}
                style="display:none;"
              />
            }
          ],
          props: {style: {
            display: 'none'
            },
          }
        },
        filterable: false,
        visible: true
      },
      {
        header: {
          label: 'Editor`s Checklist',
        },
        cell: {
          formatters: [
            (name, extraParameters) => <a href={extraParameters.rowData.editor_checklist_path} style= {{color: 'black'}} target='_blank'><i className="fas fa-external-link-alt"></i></a>
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        props: {
          style: {
            width: 85,
            textAlign: 'center',
          },
          className: "button-column"
        },
        visible: showEditColumns
      },
      {
        header: {
          label: 'Edit assignments',
        },
        cell: {
          formatters: [
            //todo create modal for edit assignments
            (name, extraParameters) => <a href={extraParameters.rowData.edit_assignments_path} style= {{color: 'black'}} target='_blank'><i className="fas fa-external-link-alt"></i></a>
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        props: {
          style: {
            width: 110,
            textAlign: 'center',
          },
          className: "button-column"
        },
        visible: showEditColumns
      },
      {
        header: {},
        cell: {
          formatters: [
            (_, extra) => {
              return <a data-action={deleteAction} style={{color: 'red'}}>X Delete</a>
            }
          ]
        },
        props: {
          style: {
            width: 85
          },
          className: "button-column"
        },
        visible: true
      }
    ];},

  project_question_set_table: function() {
    const editable = editingCell.call(this);
    const sortableHeader = sortingHeader.call(this);
    return [
      {
        property: 'selected',
        header: {
          label: 'Select One',
          formatters: [],
          props: {
            style: {
              width: 145,
              textAlign: 'center'
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            (selected, extraParameters) =>
              <input
                className="selector-radio"
                type="radio"
                name={`${this.props.name_prefix}[${'id'}]`}
                value={extraParameters.rowData['id']}
                defaultChecked={extraParameters.rowData['checked']}
                onChange={() => null}
              />
          ],
          props: {
            style: {
              textAlign: 'center'
            }
          }
        },
        filterable: false,
        visible: true
      },
      {
        property: 'id',
        header: {
          label: 'ID',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {}
        },
        visible: false
      },
      {
        property: 'title',
        header: {
          label: 'Question Set Label',
          formatters: [
            sortableHeader,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
            (title, extraParameters) => <a href={extraParameters.rowData.show_question_set_path} target='_blank'>{title}</a>
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'created_at',
        header: {
          label: 'Date Created',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
    ];},

  project_statuses_table: function() {
    const sortableHeader = sortingHeader.call(this);
    const editable = editingCell.call(this);
    const showCciProjectColumn = this.props.show_cci_project_column
    const showAssessmentProjectColumn = this.props.show_assessment_project_column
    // const projectDetailsAction = this.props.project_details_action;

    return [
      {
        property: 'course_name',
        header: {
          label: 'Course',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 150,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
          ],
          props: {
            style: {
              width: 150,
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'case_number_and_title',
        header: {
          label: 'Case Number And Title',
          formatters: [
            sortableHeader,
          ],
          props: {
            style: {
              width: 350,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              width: 350,
              textAlign: 'center',
            }
          }
        },
        visible: showCciProjectColumn
      },
      {
        property: 'case_number_and_title',
        header: {
          label: 'Question Set Item',
          formatters: [
            sortableHeader,
          ],
          props: {
            style: {
              width: 350,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              width: 350,
              textAlign: 'center',
            }
          }
        },
        visible: showAssessmentProjectColumn
      },
      {
        property: 'review_status',
        header: {
          label: 'Review Status',
          transforms: [],
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 150,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              width: 150,
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'date_last_updated',
        header: {
          label: 'Date Last Updated',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 150,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              width: 150,
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'project_team',
        header: {
          label: 'Project Team',
        },
        cell: {
          formatters: [],
          props: {
            style: {
              width: 150,
              textAlign: 'center',
            }
          }
        },
        props: {
          style: {
            width: 300,
            textAlign: 'center',
          },
          className: "button-column"
        },
        visible: true
      },
      {
        property: 'project_name',
        header: {
          label: 'Project Workflow Report',
        },
        cell: {
          formatters: [
            (project_name, extraParameters) => {
              return <a href={extraParameters.rowData.workflow_report_link} target='_blank'>{project_name}</a>
            }
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        props: {
          style: {
            textAlign: 'center',
          },
          className: "button-column"
        },
        visible: true
      },
    ];},

  project_dashboard_table: function() {
    const sortableHeader = sortingHeader.call(this);
    const projectDetailsAction = this.props.project_details_action;
    const showAquiferAdminColumns = this.props.show_aquifer_admin_columns
    const showCciProjectColumn = this.props.show_cci_project_column
    const showAssessmentProjectColumn = this.props.show_assessment_project_column
    const editable = editingCell.call(this);
    const reportAction = this.props.report_action;

    return [
      {
        property: 'name',
        header: {
          label: 'Project Name',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
            (name, extraParameters) => {
              if(showAquiferAdminColumns) {
                return <a href={extraParameters.rowData.edit_path} target='_blank'>{name}</a>
              }else{
                return <>{name}</>
              }
            }
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'reviewable',
        header: {
          label: 'Reviewable',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
            (reviewable, extraParameters) => <a href={extraParameters.rowData.aet_path} target='_blank'>{reviewable}</a>
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'priority',
        header: {
          label: 'Priority',
          formatters: [
            sortableHeader,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'start_date',
        header: {
          label: 'Start Date',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'status',
        header: {
          label: 'Status',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'workflow_status',
        header: {
          label: 'Workflow Status',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: showCciProjectColumn
      },
      {
        header: {
          label: 'Workflow Status',
        },
        cell: {
          formatters: [
            search.highlightCell,
            (name, extraParameters) => <a href={extraParameters.rowData.workflow_status_path} style= {{color: 'black'}} target='_blank'><i className="fas fa-external-link-alt"></i></a>
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        props: {
          style: {
            textAlign: 'center',
          },
          className: "button-column"
        },
        visible: showAssessmentProjectColumn
      },
      {
        header: {
          label: 'Details',
        },
        cell: {
          formatters: [
            (_, extra) => {
              return <a data-action={projectDetailsAction} data-url={extra.rowData.details_url}><i className="fas fa-external-link-alt"></i></a>
            }
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        filterable: false,
        props: {
          style: {
            width: 85,
            textAlign: 'center',
          },
          className: "button-column"
        },
        visible: true
      },
      {
        property: 'report_option',
        header: {
          label: 'Reports',
        },
        cell: {
          formatters: [
            search.highlightCell,
            (name, extraParameters) => <a href={extraParameters.rowData.report_path} style= {{color: 'black'}} target='_blank'><i className="fas fa-external-link-alt"></i></a>
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        props: {
          style: {
            width: 240,
            textAlign: 'center',
          },
          className: "button-column"
        },
        visible: showCciProjectColumn
      },
      {
        property: 'report_option',
        header: {
          label: 'Reports',
        },
        cell: {
          formatters: [
            search.highlightCell,
            (name, extraParameters) => <a href={extraParameters.rowData.report_path} style= {{color: 'black'}} target='_blank'><i className="fas fa-external-link-alt"></i></a>
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        props: {
          style: {
            width: 240,
            textAlign: 'center',
          },
          className: "button-column"
        },
        visible: showAssessmentProjectColumn
      },
    ];},


  user_report_table: function() {
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'first_name',
        header: {
          label: 'First Name',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'last_name',
        header: {
          label: 'Last Name',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'email',
        header: {
          label: 'Email',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'role_definition',
        header: {
          label: 'Role Definition',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'discipline',
        header: {
          label: 'Discipline',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'status',
        header: {
          label: 'Status',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'created_at',
        header: {
          label: 'Created at',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'subscription_end',
        header: {
          label: 'Subscription end',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
              textAlign: 'center',
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
    ];},

  dashboard_question_set_index: function() {
    const editable = editingCell.call(this);
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'exam_course',
        header: {
          label: 'Course',
          formatters: [
            sortableHeader
          ],
          props: {
            width: 300
          }
        },
        cell: {
          formatters: [search.highlightCell]
        },
        visible: true
      },
      {
        property: 'title',
        header: {
          label: 'Question Set',
          formatters: [sortableHeader]
        },
        cell: {formatters: [search.highlightCell]},
        visible: true
      },
      {
        property: 'created_at',
        header: {
          label: 'Date Created',
          props: {
            style: {
              textAlign: 'center',
            }
          },
          formatters: [sortableHeader]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell,
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'questions_count',
        header: {
          label: 'Number of Questions',
          props: {
            style: {
              textAlign: 'right',
            }
          },
          formatters: [sortableHeader]
        },
        cell: {
          formatters: [search.highlightCell],
          props: {
            style: {
              textAlign: 'right',
            }
          }
        },
        visible: true
      },
      {
        property: 'in_use',
        header: {
          label: 'In use',
          props: {
            style: {
              textAlign: 'center',
            }
          },
          formatters: [sortableHeader]
        },
        cell: {
          formatters: [],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: true
      },
      {
        property: 'new_exam_variation_url',
        header: {
          label: `Create ${this.props.exam_variation_label}`,
          props: {
            style: {
              width: 170,
              textAlign: 'center'
            }
          },
          formatters: []
        },
        cell: {
          formatters: [
            (url) => <a href={url}><i className="fa fa-edit"></i></a>
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        filterable: false,
        visible: true
      },
      {
        property: 'question_set_url',
        header: {
          label: 'Manage',
          props: {
            style: {
              textAlign: 'center'
            }
          },
          formatters: []
        },
        cell: {
          formatters: [
            (url) => <a href={url} target='_blank'><i className="fa fa-edit"></i></a>
          ],
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        filterable: false,
        visible: true
      }
    ];},

  dashboard_exam_variations_index: function() {
    const editable       = editingCell.call(this);
    const sortableHeader = sortingHeader.call(this);
    const statusOptions  = this.props.status_options
    const statusAction   = this.props.status_action
    const statusTarget   = this.props.status_target

    return [
      {
        property: 'exam_name',
        header: {
          label: this.props.exam_label,
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 300
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
        },
        visible: true
      },
      {
        property: 'form_name',
        header: {
          label: 'Form',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
        },
        visible: true
      },
      {
        property: 'version',
        header: {
          label: 'Version',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textTransform: 'capitalize'
            }
          }
        },
        visible: true
      },
      {
        property: 'status',
        header: {
          label: 'Status',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [
            (_, extraParameters) => {
              return {
                'data-exam-variation-url': extraParameters.rowData.exam_variation_url
              }
            },
            editable(
              edit.dropdown({
                props: {
                  'data-action' : statusAction
                },
                options: statusOptions
              })
            )
          ],
          formatters: [
            (status, extraParameters) => {
              return <span className="dropdown-cell">{extraParameters.rowData.status}</span>
            }
          ],
          props: {
            style: {
              textTransform: 'capitalize'
            }
          }
        },
        visible: true
      },
      {
        property: 'date_created',
        header: {
          label: 'Date Created',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            search.highlightCell,
          ]
        },
        visible: true
      },
      {
        property: 'deliveries_count',
        header: {
          label: 'Num Deliveries',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            (deliveriesCount, extraParameters) => {
              let url = extraParameters.rowData.delivered_to_path

              if (deliveriesCount === 0) {
                return deliveriesCount
              } else {
                return <a href={url} target='_blank'>{deliveriesCount}</a>
              }
            }
          ],
          props: {
            style: {
              textAlign: 'right',
            }
          }
        },
        visible: true
      },
      {
        property: 'edit_path',
        header: {
          label: 'Manage',
          props: {
            style: {
              textAlign: 'center'
            }
          }
        },
        cell: {
          formatters: [
            (url) => <a href={url}><i className="fa fa-edit"></i></a>
          ],
          props: {
            style: {
              textAlign: 'center'
            }
          }
        },
        filterable: false,
        visible: true
      }
    ];},

  dashboard_deliveries_index: function() {
    const editable = editingCell.call(this);
    const sortableHeader = sortingHeader.call(this);
    const showAquiferAdminColumns = this.props.show_aquifer_admin_columns

    return [
      {
        property: 'status',
        header: {
          label: 'Status',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            (status, extraParameters) => <a href={extraParameters.rowData.edit_path} target='_blank'>{status}</a>
          ],
        },
        visible: true
      },
      {
        property: 'group_name',
        header: {
          label: 'Program',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        visible: showAquiferAdminColumns
      },
      {
        property: 'exam_title',
        header: {
          label: this.props.exam_label,
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'nickname',
        header: {
          label: 'Nickname',
          formatters: []
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'availability_range',
        header: {
          label: 'Availability Dates',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        props: {
          style: {
            width: 300
          }
        },
        visible: true
      },
      {
        property: 'purpose',
        header: {
          label: 'Purpose',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'default_form',
        header: {
          label: 'Default Form',
          formatters: []
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        filterable: false,
        visible: showAquiferAdminColumns
      },
      {
        property: 'versions',
        header: {
          label: 'Versions',
          formatters: []
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        filterable: false,
        visible: showAquiferAdminColumns
      },
      {
        property: 'type_of_student',
        header: {
          label: 'Type of Student',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'created_at',
        header: {
          label: 'Date Added',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'student_registered_count',
        header: {
          label: '# of Students Registered',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'student_completed_count',
        header: {
          label: '# of Students Completed',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      }
    ];},

  exam_items: function() {
    const editable = editingCell.call(this);
    const conditionally_editable  = editingCell.call(this, 'scorable');
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'selected',
        header: {
          label: 'Selected',
          formatters: [
            (selected, extraParameters) => {
              let visibleRows = this.gatherRows().rows;
              let allVisibleAreSelected = visibleRows.every(row => row['selected']);

              return <div style={{display: 'inline'}}>
                <input
                  type="checkbox"
                  checked={allVisibleAreSelected}
                  onClick={() => this.toggleSelect(visibleRows, !allVisibleAreSelected)}
                  onChange={() => null}
                  style={{width: '20px'}}
                />
                {sortableHeader(selected, extraParameters)}
              </div>
            }
          ],
          props: {
            style: {
              width: 150,
              textAlign: 'center',
              zIndex: 20
            }
          }
        },
        cell: {
          transforms: [editable(Checkbox)],
          formatters: [
            (selected, extraParameters) => AddNameAttribute(
              'selected',
              extraParameters,
              this.props.name_prefix,
              (() => {
                return <input
                  className="selector-checkbox"
                  type="checkbox"
                  checked={selected}
                  onChange={() => null}
                />
              }).call()
            )
          ],
          props: {
            style: {
              textAlign: 'center'
            }
          }
        },
        filterable: false,
        visible: true
      },
      {
        property: 'scored',
        header: {
          label: 'Scored',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 105
            }
          }
        },
        cell: {
          transforms: [conditionally_editable(Checkbox)],
          formatters: [
            (scored, extraParameters) => {
              if (extraParameters.rowData.scorable) {
                return AddNameAttribute(
                  'scored',
                  extraParameters,
                  this.props.name_prefix,
                  (() => {
                    return <input
                      className="selector-checkbox"
                      type="checkbox"
                      checked={scored}
                      onChange={() => null}
                    />;
                  }).call());
              } else {
                return <span style={{ color: 'Blue' }}
                             title="This question cannot be scored because it has no historical efficiency data">
                         N/A
                       </span>;
              }
            }
          ],
          props: {
            style: {
              textAlign: 'center'
            }
          }
        },
        filterable: false,
        visible: true
      },
      {
        property: 'position',
        header: {
          label: 'Position',
          formatters: [],
          props: {
            style: {
              width: 90
            }
          }
        },
        cell: {
          transforms: [
            (_, extraParameters) => {
              return {
                'className': extraParameters.rowData.selected ? '' : 'invisible'
              }
            },
            editable(edit.input({
              props: {
                // Prevent the Enter key from submitting the form.
                'onKeyDown':   (ev) => {
                  if (ev.which == 13) {
                    ev.preventDefault()
                  }
                }
              }
            }))
          ],
          formatters: [
            (val, extra) => AddNameAttribute(
              'position',
              extra,
              this.props.name_prefix,
              <span className="number-cell">{val}</span>)
          ],
          props: {
            style: {
              textAlign: 'right'
            }
          }
        },
        filterable: false,
        visible: true
      },
      {
        property: 'question_id',
        header: {
          label: 'ID',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 110
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            (val, extra) => AddNameAttribute(
              'question_id',
              extra,
              this.props.name_prefix,
              search.highlightCell(val, extra))
          ],
          props: {
            style: {
              width: 80,
              textAlign: 'right'
            }
          }
        },
        visible: true
      },
      {
        property: 'item_label',
        header: {
          label: 'Item Label',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 200,
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'vignette',
        header: {
          label: 'Vignette',
          formatters: [
            sortableHeader
          ],
          props: {}
        },
        cell: {
          transforms: [],
          formatters: [
            (val, extra) => AddNameAttribute(
              'exam_item_id',
              extra,
              this.props.name_prefix,
              search.highlightCell(val, extra))
          ]
        },
        visible: true
      },
      {
        property: 'item_difficulty',
        header: {
          label: 'Item Difficulty',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 120
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textAlign: 'right'
            }
          }
        },
        visible: true
      },
      {
        property: 'discrimination',
        header: {
          label: 'Disc.',
          formatters: [
            sortableHeader
          ],
          props: {
            style: {
              width: 90
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              width: 80,
              textAlign: 'right'
            }
          }
        },
        visible: true
      }
    ];},

  exam_variation_deliveries_index: function() {
    const editable       = editingCell.call(this);
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'program_name',
        header: {
          label: 'Program name',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            (program_name, extraParameters) => <a href={extraParameters.rowData.edit_delivery_path} target='_blank'>{program_name}</a>
          ],
        },
        visible: true
      },
      {
        property: 'purpose',
        header: {
          label: 'Purpose',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {
            style: {
              textTransform: 'capitalize'
            }
          }
        },
        visible: true
      },
      {
        property: 'program_type',
        header: {
          label: 'Type of student',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
        },
        visible: true
      },
      {
        property: 'date_assigned',
        header: {
          label: 'Date assigned',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
        },
        visible: true
      },
      {
        property: 'date_delivered',
        header: {
          label: 'Date delivered',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
        },
        visible: true
      },
      {
        property: 'rosterings_count',
        header: {
          label: '# Administrations',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
        },
        visible: true
      },
    ];},

  rosterings_new: function() {
    const editable = editingCell.call(this);
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'selected',
        header: {
          label: 'Register',
          formatters: [
            (selected, extraParameters) => {
              let visibleRows = this.gatherRows().rows;
              let allVisibleAreSelected = visibleRows.every(row => row['selected']);

              return <div style={{display: 'inline'}}>
                <input
                  type="checkbox"
                  checked={allVisibleAreSelected}
                  onClick={() => this.toggleSelect(visibleRows, !allVisibleAreSelected)}
                  onChange={() => null}
                  style={{width: '20px'}}
                />
                {sortableHeader(selected, extraParameters)}
              </div>
            }
          ],
          props: {
            style: {
              width: 145,
              textAlign: 'center'
            }
          }
        },
        cell: {
          transforms: [editable(Checkbox)],
          formatters: [
            (selected, extraParameters) => AddNameAttribute(
              'selected',
              extraParameters,
              this.props.name_prefix,
              (() => {
                return <input
                  className="selector-checkbox"
                  type="checkbox"
                  checked={selected}
                  onChange={() => null}
                />
              }).call()
            )
          ],
          props: {
            style: {
              textAlign: 'center'
            }
          }
        },
        filterable: false,
        visible: true
      },
      {
        property: 'grdu_id',
        header: {
          label: 'GRDU ID',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {}
        },
        visible: false
      },
      {
        property: 'email',
        header: {
          label: 'Email',
          formatters: [
            (selected, extraParameters) => {
              return <div style={{display: 'inline'}}>
                {sortableHeader(selected, extraParameters)}
                {Button('button','Register Batch Emails','click->batch-add#openAdd', null, 'delivery-batch-add-btn', false)}
              </div>
            }
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            (val, extra) => AddNameAttribute(
              'grdu_id',
              extra,
              this.props.name_prefix,
              search.highlightCell(val, extra))
          ],
          props: {}
        },
        visible: true
      },
      {
        property: 'last_name',
        header: {
          label: 'Last Name',
          formatters: [
            sortableHeader
          ],
          props: {}
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'first_name',
        header: {
          label: 'First Name',
          formatters: [
            sortableHeader
          ],
          props: {}
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'timing',
        header: {
          label: `${this.props.rostering_label} Timing`,
          formatters: [
            sortableHeader
          ],
          props: {}
        },
        cell: {
          transforms: [
            editable(
              edit.dropdown({
                options: ROSTERING_TIMING_OPTIONS
              }))],
          formatters: [
            (timing, extraParameters) => AddNameAttribute(
              'timing',
              extraParameters,
              this.props.name_prefix,
              <span className="dropdown-cell">{ROSTERING_TIMING_OPTIONS.find(elem => elem.value == extraParameters.rowData.timing).name}</span>
            )
          ]
        },
        visible: true
      }
    ];},

  rosterings_edit: function() {
    const editable                = editingCell.call(this);
    const form_editable           = editingCell.call(this, 'can_change_form');
    const timing_editable         = editingCell.call(this, 'is_rostered');
    const sortableHeader          = sortingHeader.call(this);
    const examFormOptions         = this.props.exam_form_options || [{name: 'Form A', value: 'form_a'}]
    const examFormAction          = this.props.exam_form_action;
    const restartAction           = this.props.restart_action;
    const showAquiferAdminColumns = this.props.show_aquifer_admin_columns;
    const status                  = this.props.status == 'completed';
    const featureFlagOn           = this.props.feature_flag

    return [
      {
        property: 'selected',
        header: {
          label: 'Register',
          formatters: [
            (selected, extraParameters) => {
              let visibleSelectableRows = this.gatherRows().rows.filter(row => !row['freeze_selection']);
              let allVisibleAreSelected = visibleSelectableRows.every(row => row['selected']);

              return <div style={{display: 'inline'}}>
                <input
                  type="checkbox"
                  checked={allVisibleAreSelected}
                  onClick={() => this.toggleSelect(visibleSelectableRows, !allVisibleAreSelected)}
                  onChange={() => null}
                  style={{width: '20px'}}
                />
                {sortableHeader(selected, extraParameters)}
              </div>
            }
          ],
          props: {
            style: {
              width: 160,
              textAlign: 'center',
              zIndex: 20
            }
          }
        },
        cell: {
          transforms: [editable(Checkbox)],
          formatters: [
            (selected, extraParameters) => AddNameAttribute(
              'selected',
              extraParameters,
              this.props.name_prefix,
              (() => {
                return <input
                  className={`selector-checkbox ${extraParameters.rowData.freeze_selection ? 'disable-change' : ''}`}
                  type="checkbox"
                  checked={selected}
                  disabled={extraParameters.rowData.freeze_selection}
                  title={extraParameters.rowData.freeze_selection ? extraParameters.rowData.frozen_selection_tooltip : null}
                  onChange={() => null}
                />
              }).call()
            )
          ],
          props: {
            style: {
              textAlign: 'center'
            }
          }
        },
        filterable: false,
        visible: true
      },
      {
        property: 'grdu_id',
        header: {
          label: 'GRDU ID',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ],
          props: {}
        },
        visible: false
      },
      {
        property: 'email',
        header: {
          label: 'Email',
          formatters: [
            (selected, extraParameters) => {
              return <div style={{display: 'inline'}}>
                {sortableHeader(selected, extraParameters)}
                {Button('button','Register Batch Emails','click->batch-add#openAdd',null,'delivery-batch-add-btn', status)}
              </div>
            }
          ],
          props: {
            style: {
              width: 300
            }
          }
        },
        cell: {
          transforms: [],
          formatters: [
            (val, extra) => AddNameAttribute(
              'grdu_id',
              extra,
              this.props.name_prefix,
              search.highlightCell(val, extra))
          ],
          props: {
            className: 'grdu_id_cell'
          }
        },
        visible: true
      },
      {
        property: 'last_name',
        header: {
          label: 'Last Name',
          formatters: [
            sortableHeader
          ],
          props: {}
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'first_name',
        header: {
          label: 'First Name',
          formatters: [
            sortableHeader
          ],
          props: {}
        },
        cell: {
          transforms: [],
          formatters: [
            search.highlightCell
          ]
        },
        visible: true
      },
      {
        property: 'status',
        header: {
          label: 'Status',
          formatters: [
            sortableHeader
          ],
          props: {}
        },
        cell: {
          transforms: [],
          formatters: [
            (v, extra) => ActionLink(
              search.highlightCell(v, extra),
              extra,
              this.props.explain_status_action,
              this.props.explain_status_switch
            )
          ]
        },
        visible: true
      },
      {
        property: 'exam_form',
        header: {
          label: this.props.exam_variation_label,
          formatters: [
            sortableHeader
          ],
          props: {}
        },
        cell: {
          transforms: [
            form_editable(
              edit.dropdown({
                props: {'data-action' : examFormAction},
                options: examFormOptions
              }))],
          formatters: [
            (_, extraParameters) => {
              if (!extraParameters.rowData.is_rostered) return;
              const cssClass = extraParameters.rowData.can_change_form ? "dropdown-cell" : null;

              const formValue            = extraParameters.rowData.exam_form;
              const defaultFormName      = extraParameters.rowData.default_form_name;

              const optionPair = examFormOptions.find(elem => elem.value == formValue);
              let formName = optionPair ? optionPair.name : 'Default';
              if (formValue == 'default' && defaultFormName) {
                formName = `${formName} (${defaultFormName})`
              }

              return AddNameAttribute(
                'exam_form',
                extraParameters,
                this.props.name_prefix,
                <span className={cssClass}>{formName}</span>
              )
            }
          ]
        },
        props: {
          style: {
            width: 175
          }
        },
        visible: showAquiferAdminColumns
      },
      {
        property: 'assessment_version',
        header: {
          label: 'Assessment Version',
          formatters: [
            sortableHeader
          ]
        },
        cell: {
          props: {
            style: {
              textAlign: 'center',
            }
          }
        },
        visible: showAquiferAdminColumns
      },
      {
        property: 'timing',
        header: {
          label: `${this.props.rostering_label} Timing`,
          formatters: [
            sortableHeader
          ],
          props: {}
        },
        cell: {
          transforms: [
            timing_editable(
              edit.dropdown({
                options: ROSTERING_TIMING_OPTIONS
              }))],
          formatters: [
            (_, extraParameters) => {
              if (!extraParameters.rowData.is_rostered) return;
              return AddNameAttribute(
                'timing',
                extraParameters,
                this.props.name_prefix,
                <span className="dropdown-cell">{ROSTERING_TIMING_OPTIONS.find(elem => elem.value == extraParameters.rowData.timing).name}</span>
              )
            }
          ]
        },
        visible: true
      },
      {
        header: {
          label: 'Restart'
        },
        cell: {
          formatters: [
            (_, extra) => {
              if (extra.rowData.can_restart) {
                return <button
                  type="button"
                  className="restart-btn btn btn-outline-primary btn-sm"
                  data-action={restartAction}
                  data-url={extra.rowData.restart_url}
                  title={`Click to restart the timed-out ${this.props.rostering_label.toLowerCase()}.`}
                >
                </button>
              }
            }
          ]
        },
        props: {
          style: {
            width: 85
          },
          className: "button-column"
        },
        visible: featureFlagOn ? true : showAquiferAdminColumns
      }
    ];},

  document_questions: function() {
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'title',
        header: {
          label: 'Title',
          formatters: [ sortableHeader ],
          props: { width: 150 }
        },
        cell: { formatters: [
            search.highlightCell,
            (title, extraParameters) => {
              let url = extraParameters.rowData.question_edit_url

              return <a href={url} target='_blank'>{title}</a>
            }
          ]},
        visible: true
      },
      {
        property: 'question_type',
        header: {
          label: 'Question Type',
          formatters: [ sortableHeader ],
          props: { width: 150 }
        },
        cell: { formatters: [search.highlightCell] },
        visible: true
      },
      {
        property: 'vignette',
        header: {
          label: 'Vignette',
          formatters: [ sortableHeader ],
          props: { width: 400 }
        },
        cell: { formatters: [search.highlightCell] },
        visible: true
      },
      {
        property: 'images',
        header: {
          label: 'Images',
          formatters: [sortableHeader],
          props: { width: 150 }
        },
        cell: { 
          formatters: [
            search.highlightCell,
            (images, extraParameters) => {
              // Check if images is an array and log to see what it contains
              const list = extraParameters.rowData.images
              
              // Safeguard against non-array values, or if images is null/undefined
              return (Array.isArray(list) ? list : []).map((url, index) => {
                return (
                  <a 
                    key={index} 
                    href={url} 
                    target="_blank" 
                    style={{ '--background': `url(${url})` }} 
                    className="qb-thumbnail"
                    rel="noopener noreferrer"
                  >
                    Image {index + 1}
                  </a>
                );
              });
            }
          ]
        },
        visible: true
      },
      {
        property: 'stem',
        header: {
          label: 'Stem',
          formatters: [ sortableHeader ],
          props: { width: 150 }
        },
        cell: { formatters: [search.highlightCell] },
        visible: true
      },
      {
        property: 'cci_status',
        header: {
          label: 'CCI Status',
          formatters: [ sortableHeader ],
          props: { width: 150 }
        },
        cell: { formatters: [search.highlightCell] },
        visible: true
      },
    ]
  },

  case_map: function() {
    const sortableHeader = sortingHeader.call(this);

    return [
      {
        property: 'learning_objective',
        header: {
          label: 'Learning Objective',
          formatters: [ sortableHeader ]
        },
        cell: { formatters: [search.highlightCell] },
        visible: true
      },
      {
        property: 'teaching_point',
        header: {
          label: 'Teaching Point',
          formatters: [ sortableHeader ]
        },
        cell: {
          formatters: [
            search.highlightCell,
            (teaching_point, extraParameters) => {
              const styleLink = extraParameters.rowData.teaching_point_edited ? {color: 'red'} : {}
              return <a href={""} style={styleLink} onClick={() => extraParameters.rowData.openTeachingPointModal(extraParameters.rowData.teachingPointObject)}>{teaching_point}</a>
            }
          ],
        },
        visible: true
      },
      {
        property: 'assessment_question',
        header: {
          label: 'Assessment Question',
          formatters: [ sortableHeader ]
        },
        cell: {
          formatters: [
            search.highlightCell,
            (assessment_question, extraParameters) => {
              if(assessment_question!="") {
                let usedAvailableFormIcon
                if(extraParameters.rowData.isAssessmentQuestionUsedAvailableForm){
                  usedAvailableFormIcon = <i className="fas fa-file-alt"></i>
                }
                const styleLink = extraParameters.rowData.isAssessmentQuestionEdited ? {color: 'red'} : {}
                return <><a href={""} style={styleLink}
                            onClick={() => extraParameters.rowData.openAssessmentQuestionModal(extraParameters.rowData.assessmentQuestionObject)}>{assessment_question}</a>
                          {usedAvailableFormIcon}</>
              }else{
                return ""
              }
            }
          ],
        },
        visible: true
      },
      {
        property: 'current_use',
        header: {
          label: 'Question Type',
          formatters: [ sortableHeader ]
        },
        cell: {
          formatters: [
            search.highlightCell,
            (current_use, extraParameters) => {
              return <>{current_use}</>
            }
          ],
        },
        visible: true
      },
      {
        property: 'body_system',
        header: {
          label: 'Body System',
          formatters: [ sortableHeader ]
        },
        cell: {
          formatters: [
            search.highlightCell,
            (body_system, extraParameters) => {
              if(extraParameters.rowData.isMappedSystemEdited){
                return <label style={{color: 'red'}}>{body_system}</label>
              }else{
                return <>{body_system}</>
              }
            }
          ],
        },
        visible: true
      },
    ]
  }
}
